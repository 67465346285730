import React from 'react';
import './CardContainer.scss';

function CardContainer(cards: React.PropsWithChildren<{}>) {
    return(
        <div className="cards">
            {cards.children}
        </div>
    );
}

export default CardContainer