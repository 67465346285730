import React, { useState } from 'react';
import i18n from '../../i18n/index'
import { Trans } from 'react-i18next'
import logo from "../../icons/logo.svg"
import './MenuBar.scss';

function MenuBar() {
    const [checked, setChecked] = useState(false);
    const nextLanguage = i18n.language === "en" ? "hu" : "en"

    const handleLangChange = () => {
        console.log(nextLanguage)
        i18n.changeLanguage(nextLanguage)
    }

    return(
        <div className="header">
            <nav className="navbar">
                <a href="#"><img src={logo} className="logo" alt="logo" /></a>
                <ul>
                    <input onClick={() => setChecked(!checked)} checked={checked} className="menu-btn" type="checkbox" id="menu-btn"/>
                    <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>

                    <div className="menu">
                        <a href="#services" onClick={() => setChecked(false)}><li><Trans i18nKey="menuBar.menu.services"/></li></a>
                        <a href="#references" onClick={() => setChecked(false)}><li><Trans i18nKey="menuBar.menu.references"/></li></a>
                        <a href="#about-us" onClick={() => setChecked(false)}><li><Trans i18nKey="menuBar.menu.aboutUs"/></li></a>
                        <a href="#contacts" onClick={() => setChecked(false)}><li><Trans i18nKey="menuBar.menu.contacts"/></li></a>
                        <a href="" className="languageSwitcher" onClick={handleLangChange}><li><Trans i18nKey="menuBar.menu.nextLanguage"/></li></a>
                    </div>
                </ul>
            </nav>
        </div>
    );
}

export default MenuBar;