import React from 'react';
import './Card.scss';

interface CardProps {
    title: string,
    description: string,
    imageUrl?: string | null
}

function Card({title, description, imageUrl = null}: CardProps) {
    return (
        <div className="card">
            {imageUrl ? <img src={imageUrl} className="design-icon" alt="icon" /> : ""}
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    )
}

export default Card