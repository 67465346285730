import React from 'react';
import MenuBar from '../components/MenuBar/MenuBar';
import Contacts from '../components/Contacts/Contacts';
import Footer from '../components/Footer/Footer';
import './Layout.scss';

function Layout(props: React.PropsWithChildren<{}>) {
    return(
        <React.Fragment>
            <MenuBar />
            <main className="main-content">
                {props.children}
            </main>
            <Contacts/>
            <Footer />
        </React.Fragment>
    );
}

export default Layout;