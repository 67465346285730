import React, { useState } from 'react';
import i18n from '../../i18n/index'
import { Trans } from 'react-i18next'
import './References.scss';
import SimpleImageSlider from "react-simple-image-slider";
import image1 from "./images/1.jpg"
import image2 from "./images/2.jpg"
import image3 from "./images/3.jpg"
import image4 from "./images/4.jpg"
import image5 from "./images/5.jpg"

class Reference {
    imageUrl: string
    title: string
    description: string

    constructor(imageUrl: string, title: string, description: string) {
        this.imageUrl = imageUrl
        this.title = title
        this.description = description
    }
}

const references: Array<Reference> = [
    new Reference(
        image1,
        i18n.t("references.items.sideElectrodeThreader.title"),
        i18n.t("references.items.sideElectrodeThreader.description")
    ),
    new Reference(
        image2,
        i18n.t("references.items.mixedGreasingMachine.title"),
        i18n.t("references.items.mixedGreasingMachine.description")
    ),
    new Reference(
        image3,
        i18n.t("references.items.sortingAndPlacingMachine.title"),
        i18n.t("references.items.sortingAndPlacingMachine.description")
    ),
    new Reference(
        image4,
        i18n.t("references.items.powerTester.title"),
        i18n.t("references.items.powerTester.description")
    ),
    new Reference(
        image5,
        i18n.t("references.items.powderDispenser.title"),
        i18n.t("references.items.powderDispenser.description")
    )
]

function References() {
    const [selectedReference, setSelectedReference] = useState(1);

    const referenceViews: Array<React.ReactElement> = []
    references.forEach((reference, index) => {
        let imageIndex = index + 1
        referenceViews.push(
            <div className='reference-text-content' style={{display: selectedReference === imageIndex ? 'block' : 'none' }}>
                <h3>{reference.title}</h3>
                <p>{reference.description}</p>
            </div>
        )
    })
    
    return(
        <div id="references" className="content-container">
            <div className="section">
                <h1><Trans i18nKey="references.title"/></h1>
                <p><Trans i18nKey="references.description"/></p>

                <div className="references">
                    <div className="selected">
                        <div className="slider">
                            <SimpleImageSlider
                                width={"100%"}
                                height={"100%"}
                                images={references.map(reference => reference.imageUrl)}
                                showBullets={true}
                                showNavs={true}
                                autoPlay={true}
                                autoPlayDelay={10.0}
                                style={{position: 'absolute', top: '0', right: '0'}}
                                onCompleteSlide={(index, length) => {
                                    setSelectedReference(index)
                                }}
                            />
                        </div>
                        {referenceViews}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default References;