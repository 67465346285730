import React from 'react';
import Lottie from "lottie-react";
import { Trans } from 'react-i18next'
import './Welcome.scss';
import animationData from "../../animations/animation.json"

function Welcome() {
    const style = {
        width: "100%"
    }

    return(
        <div id="welcome" className="content-container">
            <div className='motto'>
                <Trans i18nKey="welcome.motto"/>
            </div>
            <Lottie animationData={animationData} style={style} />
        </div>
    );
}

export default Welcome;