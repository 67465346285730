import React from 'react';
import { Trans } from 'react-i18next'
import './AboutUs.scss';
import image from "./images/team.jpg"

function AboutUs() {
    return(
        <div id="about-us" className="content-container">
             <div className="section">
                <h1><Trans i18nKey="aboutUs.title"/></h1>
                <div className="content">
                    <div>
                        <p><Trans i18nKey="aboutUs.description"/></p>
                        <ul>
                            <li><Trans i18nKey="aboutUs.team.CsanadBakos"/></li>
                            <li><Trans i18nKey="aboutUs.team.ZoltanBajnay"/></li>
                            <li><Trans i18nKey="aboutUs.team.ZsoltFranko"/></li>
                            <li><Trans i18nKey="aboutUs.team.LeventeBakos"/></li>
                            <li><Trans i18nKey="aboutUs.team.CsabaBarna"/></li>
                            <li><Trans i18nKey="aboutUs.team.BenceCsikai"/></li>
                            <li><Trans i18nKey="aboutUs.team.RichardGobl"/></li>
                            <li><Trans i18nKey="aboutUs.team.JozsefPek"/></li>
                            <li><Trans i18nKey="aboutUs.team.PeterDohy"/></li>
                            <li><Trans i18nKey="aboutUs.team.EszterNemesHorvath"/></li>
                        </ul>
                    </div>
                    <img src={image} className="team-image" alt="The team" />
                </div>
             </div>
        </div>
    );
}

export default AboutUs;