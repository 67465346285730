import React from 'react';
import './App.scss';
import Layout from './layout/Layout';
import Welcome from './components/Welcome/Welcome';
import Services from './components/Services/Services';
import References from './components/References/References';
import AboutUs from './components/AboutUs/AboutUs';

function App() {
  return (
    <div className="app">
      <Layout>
        <Welcome/>
        <Services/>
        <References/>
        <AboutUs/>
      </Layout>
    </div>
  );
}

export default App;