import React from 'react';
import { Trans } from 'react-i18next'
import './Footer.scss';

function Footer() {
    return(
        <div id="footer" className="full-width-gray-container">
            <p>MechaCAD &copy; {new Date().getFullYear()} <Trans i18nKey="footer.allRightsReserved"/></p>
        </div>
    );
}

export default Footer;