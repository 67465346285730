import React from "react";
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "hu",
        detection: {
            order: ['localStorage'],
        },
        resources: {
            en: {
                translations: require("./en.json")
            },
            hu: {
                translations: require("./hu.json")
            }
        },
        interpolation: {
            escapeValue: false,
        },
        ns: ["translations"],
        defaultNS: "translations"
    })

i18n.languages = ["hu", "en"]
i18n.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})

document.documentElement.lang = i18n.language

export default i18n