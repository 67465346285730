import React from 'react'
import i18n from '../../i18n/index'
import { Trans } from 'react-i18next'
import './Services.scss'
import twoDimensionalDesignIcon from "../../icons/2d-design.svg"
import threeDimensionalDesignIcon from "../../icons/3d-design.svg"
import analysisIcon from "../../icons/analysis.svg"
import documentationIcon from "../../icons/documentation.svg"
import targetMachineIcon from "../../icons/targetMachine.svg"
import watercutIcon from "../../icons/watercut.svg"
import watercutQuality from "../../images/watercut_quality.jpeg"
import Card from './Views/Card'
import CardContainer from './Views/CardContainer'

function Services() {
    return(
        <div id="services" className="content-container">
            <div className="section">
                <h1><Trans i18nKey="services.title"/></h1>
                <div id="design" className="service">
                    <h2><Trans i18nKey="services.engineeringDesign.title"/></h2>
                    <p><Trans i18nKey="services.engineeringDesign.description"/></p>
                    <CardContainer>
                        <Card 
                            title={i18n.t("services.engineeringDesign.cards.2d.title")}
                            description={i18n.t("services.engineeringDesign.cards.2d.description")}
                            imageUrl={twoDimensionalDesignIcon}
                        />
                        <Card 
                            title={i18n.t("services.engineeringDesign.cards.3d.title")}
                            description={i18n.t("services.engineeringDesign.cards.3d.description")}
                            imageUrl={threeDimensionalDesignIcon}
                        />
                        <Card 
                            title={i18n.t("services.engineeringDesign.cards.analysis.title")}
                            description={i18n.t("services.engineeringDesign.cards.analysis.description")}
                            imageUrl={analysisIcon}
                        />
                        <Card 
                            title={i18n.t("services.engineeringDesign.cards.documentation.title")}
                            description={i18n.t("services.engineeringDesign.cards.documentation.description")}
                            imageUrl={documentationIcon}
                        />
                    </CardContainer>
                </div>

                <div id="target-machines" className="service">
                    <img src={targetMachineIcon} className="target-machine-icon machine-icon" alt="target-machine-icon" />
                    <div>
                        <h2><Trans i18nKey="services.targetMachines.title"/></h2>
                        <p><Trans i18nKey="services.targetMachines.description"/></p>
                    </div>
                </div>

                <div id="watercut" className="service">
                    <h2><Trans i18nKey="services.waterjetCutting.title"/></h2>
                    <div className="new-content">
                        <div>
                            <div >
                                <p><Trans i18nKey="services.waterjetCutting.description"/></p>
                            </div>

                            <CardContainer>
                                <Card
                                    title={i18n.t("services.waterjetCutting.cards.gentle.title")}
                                    description={i18n.t("services.waterjetCutting.cards.gentle.description")}
                                />
                                <Card
                                    title={i18n.t("services.waterjetCutting.cards.wideRange.title")}
                                    description={i18n.t("services.waterjetCutting.cards.wideRange.description")}
                                />
                                <Card
                                    title={i18n.t("services.waterjetCutting.cards.lowDemanding.title")}
                                    description={i18n.t("services.waterjetCutting.cards.lowDemanding.description")}
                                />
                                <Card
                                    title={i18n.t("services.waterjetCutting.cards.flexible.title")}
                                    description={i18n.t("services.waterjetCutting.cards.flexible.description")}
                                />
                                <Card
                                    title={i18n.t("services.waterjetCutting.cards.environmentallyFriendly.title")}
                                    description={i18n.t("services.waterjetCutting.cards.environmentallyFriendly.description")}
                                />
                            </CardContainer>
                        </div>
                        <img src={watercutIcon} className="watercut-icon machine-icon" alt="watercut-icon" />
                    </div>
                    <div className="old-content">
                        <div>
                            <h3><Trans i18nKey="services.waterjetCutting.materials.title"/></h3>
                            <p><Trans i18nKey="services.waterjetCutting.materials.description"/></p>
                        </div>
                        <div className="qualities">
                            <h3><Trans i18nKey="services.waterjetCutting.qualities.title"/></h3>
                            <p><Trans i18nKey="services.waterjetCutting.qualities.description"/></p>
                            <div className="content">
                                <ul>
                                    <li><Trans i18nKey="services.waterjetCutting.qualities.q1"/></li>
                                    <li><Trans i18nKey="services.waterjetCutting.qualities.q2"/></li>
                                    <li><Trans i18nKey="services.waterjetCutting.qualities.q3"/></li>
                                    <li><Trans i18nKey="services.waterjetCutting.qualities.q4"/></li>
                                    <li><Trans i18nKey="services.waterjetCutting.qualities.q5"/></li>
                                </ul>
                                <img src={watercutQuality} className="watercut-quality-image" alt="watercut_quality" />
                            </div>
                        </div>
                        <div className="ordering">
                            <h3><Trans i18nKey="services.waterjetCutting.requestForQuotation.title"/></h3>
                            <p><Trans i18nKey="services.waterjetCutting.requestForQuotation.description"/></p>
                            <p><Trans i18nKey="services.waterjetCutting.requestForQuotation.requiredDetails.description"/></p>
                            <ul>
                                <li><Trans i18nKey="services.waterjetCutting.requestForQuotation.requiredDetails.quality"/></li>
                                <li><Trans i18nKey="services.waterjetCutting.requestForQuotation.requiredDetails.material"/></li>
                                <li><Trans i18nKey="services.waterjetCutting.requestForQuotation.requiredDetails.thickness"/></li>
                                <li><Trans i18nKey="services.waterjetCutting.requestForQuotation.requiredDetails.numberOfPieces"/></li>
                                <li><Trans i18nKey="services.waterjetCutting.requestForQuotation.requiredDetails.startingSize"/></li>
                            </ul>
                            <div className="orderButton">
                                <a href="mailto:vv@mechacad.hu"><b><Trans i18nKey="services.waterjetCutting.requestForQuotation.buttonTitle"/></b></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;