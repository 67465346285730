import React from 'react';
import { Trans } from 'react-i18next'
import './Contacts.scss';

function Contacts() {

    return(
        <div id="contacts" className="full-width-gray-container">
            <div className="content-container">
                <div className="section">
                    <div className="content">
                        <div>
                            <h1><Trans i18nKey="contacts.title"/></h1>
                            <div className="contacts-data">
                                <ul>
                                    <li><Trans i18nKey="contacts.emailAddress.label"/><b><a href="mailto:mechacad@mechacad.hu"><Trans i18nKey="contacts.emailAddress.value"/></a></b></li>
                                    <li><Trans i18nKey="contacts.address.label"/><b><a href="https://goo.gl/maps/PeCzfbGUQL4pXiDF7" target="_blank"><Trans i18nKey="contacts.address.value"/></a></b></li>
                                    <li><Trans i18nKey="contacts.vatNumber.label"/><b><Trans i18nKey="contacts.vatNumber.value"/></b></li>
                                </ul>
                            </div>
                        </div>
                        <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.5319842324893!2d19.147410915414994!3d47.61578939533674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d0fb00695a99%3A0xd77efab732b793bd!2sMechacad%20Kft.!5e0!3m2!1shu!2shu!4v1652970559510!5m2!1shu!2shu" 
                                className="map"
                                title="map"
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;